.btn.btn-orange {
  background-size: 300% 100%;
  transition: all .3s ease-in-out;
  background-image: linear-gradient(to right, #fff, #fff, #FF6C4D, #FF6C4D);
  color: #555555;
  border: 1px solid #FF6C4D;
  border-radius: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.11);
  height: 50px;
  width: 209px;
  outline: none;

  &:hover {
    background-position: 100% 0;
    transition: all .3s ease-in-out;
    color: #ffffff;
    outline: none;
  }

  &:active {
    box-shadow: none;
    transition: all .3s ease-in-out;
    outline: none;
    position: relative;
    top: 1px;
  }

  &:focus {
    outline: none;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.11);
  }
}

@media (max-width: 767.98px)  {
  .btn.btn-orange {
    width: auto;
  }
}