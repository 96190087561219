.example-font {
  margin-bottom: 60px;

  &:last-child {
    margin-bottom: 0;
  }
}

.example-font__title {
  font-size: 18px;
  line-height: 21px;
  color: #6C6C6C !important;
  margin-bottom: 31px;
  display: block;
}

.example-font__content {
  list-style-type: none;
  padding-left: 85px;
}

.example-font__text {
  margin-bottom: 0;
}

.example-font__text.font-family-Oranienbaum {

}

.example-font__item {
  display: flex;
  align-items: baseline;
}

.example-font__fz {
  display: block;
  margin-left: 20px;
  color: #6C6C6C !important;
  font-size: 14px;
  line-height: 16px;
}

.example-font-Oranienbaum {
  .example-font__item {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.example-font-PT {
  .example-font__item {
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .example-font__content {
    padding-left: 0;
  }
}


@media (max-width: 767.98px) {
  .example-font__title {
    margin-bottom: 18px;
  }

  .example-font-PT .example-font__item {
    margin-bottom: 30px;
  }

  .example-font-Oranienbaum .example-font__item {
    margin-bottom: 16px;
  }
}