.burger {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #000000;
  box-shadow: -2px 4px 14px rgba(0, 0, 0, 0.11);
  border-radius: 3px;
  padding: 12px 29px 11px 21px;
  margin-left: auto;
}

.bar1, .bar2, .bar3 {
  width: 30px;
  height: 3px;
  background-color: #333;
  margin: 5px 0;
  transition: 0.4s;
  border-radius: 10px;
}

.bar2 {
  width: 21px;
}

.change {

  .bar1 {
    transform: rotate(-45deg) translate(-5px, 6px) ;
  }

  .bar2 {
    opacity: 0;
  }

  .bar3 {
    transform: rotate(45deg) translate(-5px, -8px) ;
  }

}

@media (max-width: 767.98px) {
  .burger {
    padding: 3px 14px;
    margin-top: 6px;
  }

  .bar1, .bar2, .bar3 {
    width: 24px;
  }

  .bar2 {
    width: 15px;
  }
}