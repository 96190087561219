.section-second {
  background: #DCDAE1;
  padding-top: 136px;
  padding-bottom: 180px;
  overflow: hidden;
  position: relative;
}

.section-second__list {
  list-style-type: none;
  margin-top: 43px;
  padding-left: 0;
}

.section-second__content {
  display: flex;
  align-items: baseline;
}

.section-second__item {
  margin-bottom: 35px;

  &:nth-child(2) {
    padding-left: 46px;
  }

  &:last-child {
    padding-left: 103px;
    margin-bottom: 0;
  }
}

.section-second-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
}

.section-second-bg-1 {
  width: 57%;
  background: url("../img/section-second-bg.svg") no-repeat right;
  background-size: cover;
  z-index: 1;
}

.section-second-bg-2 {
  width: 47%;
  background: url("../img/section-second-bg-2.svg") no-repeat right;
  background-size: cover;
  z-index: 2;
}

.section-second-bg-3 {
  width: 37%;
  background: url("../img/section-second-bg-3.svg") no-repeat right;
  background-size: cover;
  z-index: 3;
}

.section-second-laptop-img {
  background: url("../img/laptop-img-1.png") no-repeat center;
  background-size: 100% auto;
  width: 1130px;
  height: 527px;
  position: absolute;
  z-index: 4;
  right: 185px;
  bottom: -55px;
}


@media (max-width: 1199.98px) {
  .section-second-laptop-img {
    right: 50px;
  }
}

@media (max-width: 991.98px) {
  .section-second {
    padding-top: 50px;
    padding-bottom: 100px;
  }

  .section-second-bg-1 {
    width: 65%;
  }

  .section-second-bg-2 {
    width: 55%;
  }

  .section-second-bg-3 {
    width: 45%;
  }

  .section-second-laptop-img {
    right: -120px;
  }
}

@media (max-width: 767.98px) {
  .section-second {
    padding-bottom: 362px;
    padding-top: 33px;
  }

  .section-second-laptop-img {
    width: 560px;
    height: 261px;
    left: 20px;
    bottom: -63%;
  }

  .section-second__item,
  .section-second__item:nth-child(2),
  .section-second__item:nth-child(3){
    padding-left: 0;
  }

  .section-second-bg-1 {
    width: 151%;
  }

  .section-second-bg-2 {
    width: 120%;
  }

  .section-second-bg-3 {
    width: 100%;
  }
}