.section-fourth {
  padding-top: 90px;
  padding-bottom: 40px;
  overflow: hidden;
  
  .h3 {
    color: #000000;
    margin-bottom: 37px;
  }
}

.section-fourth-content__text {
  font-size: 24px;
  line-height: 168.2%;
  color: #333333;
  margin-bottom: 0;
}

.section-fourth-content__item-content-title {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  color: #333333;
  margin-bottom: 16px;
  display: block;
}

.section-fourth-content__list {
  padding-left: 0;
  list-style-type: none;
  margin-top: 35px;
}

.section-fourth-content__item {
  margin-bottom: 56px;

  &:last-child {
    margin-bottom: 0;
  }
}

.section-fourth-content__item-container {
  display: flex;
}

.section-fourth-content__icon {
  display: flex;
  justify-content: center;
  min-width: 102px;
}

.section-fourth__img-container-first {

}

.section-fourth__img-container-second {
  height: 638px;

  img {
    position: absolute;
    right: 0;
    top: 0;
  }
}

.section-fourth__img-container-third {
  height: 677px;

  img {
    position: absolute;
    left: 0;
    top: 0;
  }
}

@media (max-width: 991.98px) {
  .section-fourth-content__text {
    font-size: 18px;
  }

  .section-fourth-content__item-content-title {
    font-size: 24px;
    margin-bottom: 0;
  }

  .section-fourth-content__item {
    margin-bottom: 30px;
  }

  .section-fourth {
    padding-top: 50px;
    padding-bottom: 0;
  }
}

@media (max-width: 767.98px) {
  .section-fourth__img-container {

  }

  .section-fourth-content__item-container {
    display: block;
  }

  .section-fourth-content__text {
    font-size: 18px;
  }

  .section-fourth-content__item-content-title {
    font-size: 24px;
  }

  .section-fourth-content__icon {
    min-width: 50px;
    float: left;
  }

  .section-fourth-content__textContainer {
    margin-bottom: 40px;
  }

  .section-fourth .h3 {
    margin-bottom: 20px;
  }

  .section-fourth__img-container-second {
    height: 320px;
    position: relative;
    top: 25px;
  }

  .section-fourth__img-container-third {
    display: none;
  }


}