* {
  font-family: 'Roboto', sans-serif;
}

body.active {
  overflow: hidden;
}

.section {
  overflow: hidden;
}

h2.h2 {
  font-weight: bold;
  font-size: 64px;
  line-height: 75px;
  color: #000000;
}
h3.h3 {
  font-weight: bold;
  font-size: 48px;
  line-height: 56px;
}

h4.h4 {
  font-weight: bold;
  font-size: 36px;
  line-height: 42px;
  text-align: center;
  color: #000000;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo-main {
  background: url("../img/logo-main.svg") no-repeat center;
  background-size: 100% auto;
  width: 184px;
  height: 49px;
  display: block;
}

.logo-main_mr {
  margin-right: 49px;
}

.logo-whine {
  background: url("../img/wineShip-logo.svg") no-repeat center;
  background-size: 100% auto;
  width: 159px;
  height: 49px;
  display: block;
}

.section-link {
  font-size: 16px;
  line-height: 19px;
  color: #555555;

  &:hover {
    text-decoration: none;
    color: #555555;
  }
}

.video-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 0;
}

.video-bg > video {
  position: absolute;
  top: 0;
  left: 70px;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
}

@supports (object-fit: cover) {
  .video-bg > video {
    top: 0;
    left: 70px;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.main-text {
  font-size: 24px;
  line-height: 168.2%;
  color: #333333;
  margin-bottom: 0;
}

.red-arrow {
  background: url("../img/red-triangle-svg.svg") no-repeat center;
  background-size: 100% auto;
  min-width: 15px;
  height: 24px;
  display: block;
  position: relative;
  top: 4px;
}

.red-arrow_mr {
  margin-right: 20px;
}

.red-arrow_mr-header {
  margin-right: 30px;
}

.zindex-4 {
  z-index: 5;
}

.section-bg-black {
  background: #111111;

  * {
    color: #ffffff;
  }
}

.measuring-jug-icon {
  background: url("../img/measuring-jug.svg") no-repeat center;
  background-size: 100% auto;
  width: 36px;
  height: 36px;
  display: block;
}

.event-icon {
  background: url("../img/event-icon.svg") no-repeat center;
  background-size: 100% auto;
  width: 35px;
  height: 35px;
  display: block;
}

.img-setting-wineship {
  position: relative;
}

.font-family-Oranienbaum {
  font-family: 'Oranienbaum', serif;
}

.font-family-PT {
  font-family: 'PT Sans Narrow', sans-serif;
}

.fz42 {
  font-size: 42px;
}

.fz36 {
  font-size: 36px;
}

.fz32 {
  font-size: 32px;
}

.fz24 {
  font-size: 24px;
}

.fz18 {
  font-size: 18px;
}

.fz16 {
  font-size: 16px;
}

.app.active {
  position: relative;
  filter: blur(40px);

  &:before {
    content: '';
    background: rgba(0, 0, 0, 0.77);
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
  }
}


@media (min-width: 1200px) {
  .container {
    max-width: 1254px !important;
  }
}

@media (max-width: 1199.98px) {
  .video-bg > video {
    position: absolute;
    top: 44px;
    left: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: none;
  }
}

@media (max-width: 991.98px) {
  .video-bg > video {
    position: absolute;
    top: 44px;
    left: 0;
    min-width: 118%;
    min-height: 127%;
    object-fit: contain;
  }

  h2.h2 {
    font-size: 48px;
    line-height: normal;
  }

  h3.h3 {
    font-size: 32px;
    line-height: normal;
  }

  h4.h4 {
    font-size: 26px;
  }

  .main-text {
    font-size: 18px;
  }

  .app {
    padding-top: 0;
  }
}

@media (max-width: 767.98px) {
  .video-bg {
    display: none;
  }

  .logo-main_mr {
    margin-right: 15px;
  }

  .main-text {
    font-size: 18px;
  }

  h2.h2 {
    font-size: 36px;
    line-height: normal;
  }

  h3.h3 {
    font-size: 36px;
    line-height: normal;
  }

  h4.h4 {
    font-size: 22px;
    line-height: normal;
  }

  .measuring-jug-icon {
    width: 26px;
    height: 26px;
  }

  .event-icon {
    width: 22px;
    height: 22px;
    margin-top: 9px;
  }

  .img-setting-wineship {
    left: -35px;
    position: absolute;
    max-width: 355px;
  }

  .pt-90px {
    padding-top: 90px;
  }

  .laptop-section-fourth {
    max-width: 514px;
  }

  .app.active {
    filter: none;
  }

  .app.active:before {
    display: none;
  }
}